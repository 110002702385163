/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-01-15 15:51:44
 * @FilePath: /mediatom-web/src/api/mediaDashboard.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const dashboardApi = {
  // 媒体首页看板数据
  getRevenueData: '/meidaDashboard/getRevenueData',
  // 14日ecpm曲线
  getEcpmTrend: '/meidaDashboard/getEcpmTrend',
  // 数据趋势曲线
  getDataTrend: '/meidaDashboard/getDataTrend',
  // 广告样式收益占比扇形图
  getPositionPie: '/meidaDashboard/getPositionPie',
  // 流量漏斗
  getFunnelData: '/meidaDashboard/getFunnelData',
  // 应用数据
  getAppData: '/meidaDashboard/getAppData',
  getPlatPieData: 'meidaDashboard/platPie'
}
export function getRevenueData () {
  return request({
    url: dashboardApi.getRevenueData,
    method: 'post'
  })
}

export function getEcpmTrend () {
  return request({
    url: dashboardApi.getEcpmTrend,
    method: 'post'
  })
}
export function getDataTrend (data) {
  return request({
    url: dashboardApi.getDataTrend,
    method: 'post',
    data
  })
}
export function getPositionPie () {
  return request({
    url: dashboardApi.getPositionPie,
    method: 'post'
  })
}
export function getFunnelData () {
  return request({
    url: dashboardApi.getFunnelData,
    method: 'post'
  })
}
export function getAppData (data) {
  return request({
    url: dashboardApi.getAppData,
    method: 'post',
    data
  })
}

export function getPlatPieData (data) {
  return request({
    url: dashboardApi.getPlatPieData,
    method: 'post',
    data
  })
}
